import { useTranslate, BaseKey } from "@refinedev/core";
import { NumberField } from "@refinedev/antd";
import {
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    EyeOutlined,
    FormOutlined,
    MoreOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import {
    Avatar,
    Divider,
    InputNumber,
    Dropdown,
    Menu,
    Typography,
    List,
    Space,
    Button,
} from "antd";
import styled from '@emotion/styled'

const Card= styled.div`
  display: flex;

  .card {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #edebe9;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    img {
      border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
      margin: 1.0rem;
    }
  }

  .card__item {
    padding: 0.25rem 1rem;
  }

  .card__sub {
    padding: 0 18px;
  }

  .flexible {
    flex-grow: 1;
  }
  .card__footer {
    padding: 18px;
    overflow: hidden;
    border-top: 1px solid #dedede;
  }
`

import { ICustomer } from "../../interfaces";

const { Text, Paragraph } = Typography;

type CustomerItemProps = {
    item: ICustomer;
    editShow: (id?: BaseKey) => void;
};

export const CustomerCard: React.FC<CustomerItemProps> = ({
    item,
    editShow,
}) => {
    const t = useTranslate();

    return (
      <List.Item>
        <Card>
          <div className="card">
            <img src={item.logo} />
            <div className="card__item">
              <h2>{item.name}</h2>
            </div>
            <div className="card__item flexible">{item.description}</div>
            <div className="card__footer">
              <Space
                direction="horizontal"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button>
                  <EyeOutlined key="setting" />
                </Button>
                <Button onClick={() => editShow(item.id)}>
                  <EditOutlined key="edit" />
                </Button>
                <Button>
                  <DeleteOutlined key="delete" />
                </Button>
                {/* <Button>
                  <EllipsisOutlined key="ellipsis" />
                </Button> */}
              </Space>
            </div>
          </div>
        </Card>
      </List.Item>
    );
};