import { TableProps } from "antd";
import { IProduct } from "interfaces";
import { BaseRecord } from "@refinedev/core";

export const stringToColor = (str: string) => {
  if (str == "Focus") {
    return "#005488";
  }

  if (str == "Tura") {
    return "#000";
  }

  if (str == "pågående") {
    return "#23b06e";
  }

  if (str == "avslutat") {
    return "#3eb2fa";
  }

  if (str == "pausat") {
    return "#f5ad42";
  }

  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};


export const hashCode = (value : string) => {
  var hash = 0,
    i, chr;
  if (value?.length === 0) return hash;
  for (i = 0; i < value?.length; i++) {
    chr = value?.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function getUniqueValuesFromArrayInArray<T>(dataSource: readonly T[], key: keyof T) : string[]{
  const allIds = dataSource?.flatMap(obj => obj[key] as unknown as string);
  const uniqueIDs = new Set<string>(allIds);
  return Array.from<string>(uniqueIDs);
}