import { Account, Appwrite, Storage, Databases, Query } from "@refinedev/appwrite";

const APPWRITE_URL = process.env.REACT_APP_API_URL ?? "http://localhost/v1";
const APPWRITE_PROJECT = process.env.REACT_APP_PROJECT_ID ?? "";

const appwriteClient = new Appwrite();

appwriteClient
.setEndpoint(APPWRITE_URL)
.setProject(APPWRITE_PROJECT);

const documentData = new Databases(appwriteClient);
const account = new Account(appwriteClient);
const storage = new Storage(appwriteClient);

export { appwriteClient, account, storage, documentData, Query };