import { CrudFilters, HttpError, useMany, useTitle, useOne } from "@refinedev/core";
import React, { useState } from 'react'

import { EditButton, List, SaveButton, TagField, useDrawerForm, FilterDropdown, useSimpleList, Edit, BooleanField, TextField, useSelect, DeleteButton, CreateButton } from "@refinedev/antd";
import { Button, Checkbox, Drawer, Form, Input, Space, Table, List as AntdList } from "antd";
import { ICustomer, IProject } from "interfaces";
import { APP_WRITE_CUSTOMER_ID, APP_WRITE_PROEJCT_ID, APP_WRITE_TAG_ID } from "utility/constants";
import styled from '@emotion/styled'
import { CreateCustomer } from "./create";
import { CustomerCard } from "components/customer/customercard";
import { EditCustomer } from "./edit";
import { EditOutlined, EllipsisOutlined, EyeOutlined } from "@ant-design/icons";

const TableHeader= styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

interface ISearch {
    name: string;
}

export const CustomerList: React.FC = () => {
    const { listProps, searchFormProps } = useSimpleList<ICustomer, HttpError, ISearch>(
        {
            onSearch: (values) => {
                const filters: CrudFilters = [];

                filters.push({
                  field: "name",
                  operator: "contains",
                  value: values.name == "" ? undefined : values.name,
                });

                return filters;
            },
            pagination: {
                pageSize: 12,
            },
            syncWithLocation: false
        }
    );

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        id: customerId,
        show: showEditDrawer,
    } = useDrawerForm<ICustomer, HttpError>({
        action: "edit",
        redirect: false,
    });


    const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: showCreateDrawer,
  } = useDrawerForm<ICustomer, HttpError>({
      action: "create",
      resource: APP_WRITE_CUSTOMER_ID,
      redirect: false,
  });
    
    return (
      <>
        <List title={"Våra kunder"}>
          {/* Table header filters */}
          <Form {...searchFormProps} layout="inline">
            <TableHeader>
              <Space>
                <Form.Item name="name">
                  <Input placeholder="Sök kund" />
                </Form.Item>
                <Button type="primary" onClick={searchFormProps.form?.submit}>
                  Filtrera
                </Button>
              </Space>
              <CreateButton
                resource={APP_WRITE_CUSTOMER_ID}
                onClick={() => showCreateDrawer()}
              />
            </TableHeader>
          </Form>

          <br></br>

          <AntdList
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
              xl: 6,
              xxl: 6,
            }}
            {...listProps}
            renderItem={(item) => (
              <CustomerCard item={item} editShow={showEditDrawer} />
            )}
          />

          {/* <Table
            {...tableProps}
            rowKey="id"
          >
            <Table.Column dataIndex="id" title="Id" />
            <Table.Column dataIndex="name" title="Namn" sorter/>
            <Table.Column dataIndex="orgNumber" title="OrgNummer"/>
            <Table.Column<ICustomer>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => showEditDrawer(record.id)}
                  />
                  <DeleteButton hideText size="small" recordItemId={record.id}/>
                </Space>
              )}
            />
          </Table> */}
        </List>
        <EditCustomer
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          editId={customerId}
        ></EditCustomer>
        <CreateCustomer
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
        ></CreateCustomer>
      </>
    );
};