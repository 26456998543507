import { useTranslate, useApiUrl, BaseKey } from "@refinedev/core";

import { Edit, useSelect } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
    DatePicker,
} from "antd";

import { APP_WRITE_SUBSCRIPTION_ID } from "utility/constants";
import { CustomerSelectOptions } from "components/customerSelectOption";
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import locale from 'antd/es/date-picker/locale/sv_SE';
import TextArea from "antd/es/input/TextArea.js";

type EditProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    editId?: BaseKey;
};

export const EditProspect: React.FC<EditProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    editId,
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const { customerSelectProps } = CustomerSelectOptions();

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Edit
          title={"Redigera leads"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_SUBSCRIPTION_ID}
          recordItemId={editId}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} layout="vertical">
          <Form.Item
              label="Namn"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label="Omsättning (miljoner kr)"
                name="revenue"
                rules={[
                    {
                    required: true,
                    },
                ]}
                >
                <InputNumber min={0}  step="1"/>
            </Form.Item>
            <Form.Item label="Behov" name="needs">
              <TextArea rows={10}></TextArea>
            </Form.Item>
            <Form.Item
                label="Senaste kontakt"
                name="latestContact"
                getValueProps={(i) => ({value: dayjs(i)})}
                >
                <DatePicker locale={locale}/>
            </Form.Item>
            <Form.Item label={"Nuvarande plattform"} name="currentPlattform">
              <Select
                placeholder={"Välj plattform"}
                style={{ width: "100%" }}
                options={[
                  {
                    label: "Norce",
                    value: "Norce",
                  },
                  {
                    label: "Centra",
                    value: "Centra",
                  },
                  {
                    label: "Quickbutik",
                    value: "Quickbutik",
                  },
                  {
                    label: "Nyehandel",
                    value: "Nyehandel",
                  },
                  {
                    label: "Wikinggruppen",
                    value: "Wikinggruppen",
                  },
                  {
                    label: "Shopify",
                    value: "Shopify",
                  },
                  {
                    label: "WooCommerce",
                    value: "WooCommerce",
                  },
                  {
                    label: "Wix",
                    value: "Wix",
                  },
                  {
                    label: "Abicart",
                    value: "Abicart",
                  },
                  {
                    label: "Magento",
                    value: "Magento",
                  },
                  {
                    label: "PrestaShop",
                    value: "PrestaShop",
                  },
                  {
                    label: "Askås",
                    value: "Askås",
                  },
                  {
                    label: "BigCommerce",
                    value: "BigCommerce",
                  },
                  {
                    label: "Weebly",
                    value: "Weebly",
                  },                  {
                    label: "3dcart",
                    value: "3dcart",
                  },                  {
                    label: "Ecwid",
                    value: "Ecwid",
                  },                  {
                    label: "Nordisk E-handel",
                    value: "Nordisk E-handel",
                  },                  {
                    label: "Annan",
                    value: "Annan",
                  }
                ]}
              />
            </Form.Item>
            <Form.Item label={"Land"} name="country">
              <Select
                placeholder={"Välj land"}
                style={{ width: "100%" }}
                options={[
                  {
                    label: "Sverige",
                    value: "Sverige",
                  },
                  {
                    label: "Danmark",
                    value: "Danmark",
                  },
                  {
                    label: "Finland",
                    value: "Finland",
                  },
                  {
                    label: "Norge",
                    value: "Norge",
                  },
                  {
                    label: "England",
                    value: "England",
                  },
                  {
                    label: "Tyskland",
                    value: "Tyskland",
                  }
                ]}
              />
            </Form.Item>
          </Form>
        </Edit>
      </Drawer>
    );
};
