import {
    Refine,
    AuthBindings,
    Authenticated,
    IResourceItem,
    Action,
    CanAccess
} from "@refinedev/core";
import {
    notificationProvider,
    ThemedLayoutV2,
    ErrorComponent,
    AuthPage,
    RefineThemes,
} from "@refinedev/antd";
import {
    ApartmentOutlined,
    DashboardOutlined, DollarOutlined, FieldTimeOutlined, PartitionOutlined, ProjectOutlined, ScheduleOutlined, TagsOutlined, UserOutlined,
} from "@ant-design/icons";

// import dataProvider from "@refinedev/simple-rest";
import {
    AppwriteException,
    Query,
    dataProvider,
    liveProvider,
} from "@refinedev/appwrite";

import routerProvider, {
    NavigateToResource,
    CatchAllNavigate,
    UnsavedChangesNotifier,
    DocumentTitleHandler,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Avatar, ConfigProvider } from "antd";

import "@refinedev/antd/dist/reset.css";

import { DashboardPage } from "pages/dashboard";
import { appwriteClient, account, documentData } from "utility/appwriteClient";
import { Login } from "pages/login";
import { Logo } from "components/logo";
import { APP_WRITE_CUSTOMER_ID, APP_WRITE_DB_ID, APP_WRITE_PROEJCT_ID, APP_WRITE_PROSPECT_ID, APP_WRITE_SUBSCRIPTION_ID, APP_WRITE_TIME_REPORT_USER_ID, APP_WRITE_TIME_ROW_ID } from "utility/constants";
import { CustomerList } from "pages/customers/list";
import { ProjectList } from "pages/projects/list";
import { TimeRowList } from "pages/timeRows/list";
import { InvoicingList } from "pages/invoicing/list";
import { SubscriptionList } from "pages/subscriptions/list";
import { Header } from "components/header";
import { ProspectList } from "pages/prospects/list";

interface PageTitleHandlerProps {
    resource?: IResourceItem;
    action?: Action;
    params?: Record<string, string | undefined>;
    pathname?: string;
    autoGeneratedTitle: string;
}

const customTitleHandler = ({ resource, action, params } : PageTitleHandlerProps) => {
    let title = "Hyperloop";

    if(resource && action) {
        title = `${resource?.meta?.label} | Hyperloop`;
    }

    return title;
};

const authProvider: AuthBindings = {
    login: async ({ email, password }) => {
        try {
            await account.createEmailSession(email, password);
            return {
                success: true,
                redirectTo: "/",
            };
        } catch (e) {
            const { type, message, code } = e as AppwriteException;
            return {
                success: false,
                error: {
                    message,
                    name: `${code} - ${type}`,
                },
            };
        }
    },
    logout: async () => {
        try {
            await account.deleteSession("current");
        } catch (error: any) {
            return {
                success: false,
                error,
            };
        }

        return {
            success: true,
            redirectTo: "/login",
        };
    },
    onError: async (error) => {
        console.error(error);
        return { error };
    },
    check: async () => {
        try {
            const session = await account.get();
            
            try {
              await documentData.getDocument(
                APP_WRITE_DB_ID,
                APP_WRITE_TIME_REPORT_USER_ID,
                session.$id
              );
            }catch(e){
                console.log(e)
                await documentData.createDocument(APP_WRITE_DB_ID, APP_WRITE_TIME_REPORT_USER_ID, session.$id, {
                    name: session.name,
                });
            }

            if (session) {
                return {
                    authenticated: true,
                };
            }
        } catch (error: any) {
            return {
                authenticated: false,
                error: error,
                logout: true,
                redirectTo: "/login",
            };
        }

        return {
            authenticated: false,
            error: {
                message: "Check failed",
                name: "Session not found",
            },
            logout: true,
            redirectTo: "/login",
        };
    },
    getPermissions: async () => {
        const user = await account.get();
        return user?.prefs?.role;
    },
    getIdentity: async () => {
        // const user = await account.get();

        // if (user) {
        //     return {...user , avatar: <Avatar icon={<UserOutlined />} />};
        // }
        //avatar
        return null;
    },
};

const App: React.FC = () => {
    return (
      <BrowserRouter>
        <ConfigProvider theme={RefineThemes.Orange}>
          <Refine
            dataProvider={dataProvider(appwriteClient, {
              databaseId: APP_WRITE_DB_ID,
            })}
            liveProvider={liveProvider(appwriteClient, {
              databaseId: APP_WRITE_DB_ID,
            })}
            accessControlProvider={{
              can: async ({ resource, action, params }) => {
                const user = await account.get();
                const role = user?.prefs?.role;
                if (role == "admin") {
                  return { can: true };
                }
                if (role == "standard") {
                  if (
                    (resource === APP_WRITE_TIME_ROW_ID && action === "list") ||
                    (resource === APP_WRITE_TIME_ROW_ID &&
                      action === "create") ||
                    (resource === APP_WRITE_TIME_ROW_ID && action === "edit") ||
                    (resource === APP_WRITE_TIME_ROW_ID &&
                      action === "delete")
                  ) {
                    return {
                      can: true,
                    };
                  }
                }

                return {
                  can: false,
                  reason: "Unauthorized",
                };
              },
              options: {
                buttons: {
                  enableAccessControl: true,
                  hideIfUnauthorized: false,
                },
              },
            }}
            authProvider={authProvider}
            routerProvider={routerProvider}
            resources={[
              {
                name: "dashboard",
                list: "/",
                meta: {
                  label: "Dashboard",
                  icon: <DashboardOutlined />,
                },
              },
              {
                name: APP_WRITE_PROSPECT_ID,
                list: "/prospects",
                meta: {
                  label: "Leads",
                  icon: <DollarOutlined />,
                },
              },
              {
                name: APP_WRITE_CUSTOMER_ID,
                list: "/customers",
                // create: "/posts/create",
                // edit: "/posts/edit/:id",
                // show: "/posts/show/:id",
                meta: {
                  label: "Kunder",
                  icon: <ApartmentOutlined></ApartmentOutlined>,
                },
              },
              {
                name: APP_WRITE_PROEJCT_ID,
                list: "/projects",
                // create: "/posts/create",
                // edit: "/posts/edit/:id",
                // show: "/posts/show/:id",
                meta: {
                  label: "Projekt",
                  icon: <ProjectOutlined></ProjectOutlined>,
                },
              },
              {
                name: APP_WRITE_TIME_ROW_ID,
                list: "/timesheets",
                // create: "/posts/create",
                // edit: "/posts/edit/:id",
                // show: "/posts/show/:id",
                meta: {
                  label: "Tidsrapportering",
                  icon: <FieldTimeOutlined></FieldTimeOutlined>,
                },
              },
              {
                name: APP_WRITE_SUBSCRIPTION_ID,
                list: "/subscriptions",
                // create: "/posts/create",
                // edit: "/posts/edit/:id",
                // show: "/posts/show/:id",
                meta: {
                  label: "Driftabonnemang",
                  icon: <ScheduleOutlined></ScheduleOutlined>,
                },
              },
              {
                name: "invoicing",
                list: "/invoicing",
                meta: {
                  label: "Fakturering",
                  icon: <DollarOutlined />,
                },
              }
            ]}
            notificationProvider={notificationProvider}
            options={{
              liveMode: "auto",
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2
                      Title={Logo}
                      Header={() => {
                        return <Header></Header>;
                      }}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                {/* <Route index element={<DashboardPage />} /> */}

                <Route path="/">
                  <Route
                    index
                    element={
                      <CanAccess
                        fallback={<h1>Gött med Gött...</h1>}
                      >
                        <DashboardPage />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/customers">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={APP_WRITE_CUSTOMER_ID}
                        action="list"
                        fallback={<h1>Försök inte med skumrask...</h1>}
                      >
                        <CustomerList />
                      </CanAccess>
                    }
                  />
                </Route>
                <Route path="/projects">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={APP_WRITE_PROEJCT_ID}
                        action="list"
                        fallback={<h1>Försök inte med skumrask...</h1>}
                      >
                        <ProjectList />
                      </CanAccess>
                    }
                  />
                </Route>
                <Route path="/timesheets">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={APP_WRITE_TIME_ROW_ID}
                        action="list"
                        fallback={<h1>Försök inte med skumrask...</h1>}
                      >
                        <TimeRowList />
                      </CanAccess>
                    }
                  />
                </Route>
                <Route path="/invoicing">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource="invoicing"
                        action="list"
                        fallback={<h1>Försök inte med skumrask...</h1>}
                      >
                        <InvoicingList />
                      </CanAccess>
                    }
                  />
                </Route>
                <Route path="/subscriptions">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={APP_WRITE_SUBSCRIPTION_ID}
                        action="list"
                        fallback={<h1>Försök inte med skumrask...</h1>}
                      >
                        <SubscriptionList />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/prospects">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={APP_WRITE_PROSPECT_ID}
                        action="list"
                        fallback={<h1>Försök inte med skumrask...</h1>}
                      >
                        <ProspectList />
                      </CanAccess>
                    }
                  />
                </Route>

                {/* <Route path="/posts">
                    <Route index element={<PostList />} />
                    <Route path="edit/:id" element={<PostEdit />} />
                    <Route path="show/:id" element={<PostShow />} />
                </Route> */}
              </Route>

              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource resource="dashboard" />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                <Route
                  path="/forgot-password"
                  element={<AuthPage type="forgotPassword" />}
                />
                <Route
                  path="/update-password"
                  element={<AuthPage type="updatePassword" />}
                />
              </Route>

              <Route
                element={
                  <Authenticated>
                    <ThemedLayoutV2>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
            <DocumentTitleHandler handler={customTitleHandler} />
          </Refine>
        </ConfigProvider>
      </BrowserRouter>
    );
};

export default App;
