import { useTranslate } from "@refinedev/core";
import { Create } from "@refinedev/antd";
import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    ButtonProps,
    Typography,
    Grid,
    InputNumber,
} from "antd";

const { Text } = Typography;

import { APP_WRITE_PROEJCT_ID } from "utility/constants";
import TextArea from "antd/es/input/TextArea";
import { CustomerSelectOptions } from "components/customerSelectOption";
import Select from "antd/lib/select";

type CreateProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreateProject: React.FC<CreateProps> = ({
    drawerProps,
    formProps,
    saveButtonProps
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();

    const { customerSelectProps } = CustomerSelectOptions();

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Create
          title={"Skapa projekt"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_PROEJCT_ID}
          goBack={false}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} layout="vertical">
            <Form.Item
              label="Namn"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label="Timpris (kr)"
                name="hourPrice"
                rules={[
                    {
                    required: true,
                    },
                ]}
                >
                <InputNumber min={0}  step="50"/>
            </Form.Item>
            <Form.Item label="Beskrivning" name="description">
              <TextArea rows={10}></TextArea>
            </Form.Item>
            <Form.Item label="Kund" name="customerId">
              <Select
                placeholder="Välj kund"
                style={{ width: "100%" }}
                {...customerSelectProps}
              />
            </Form.Item>
            <Form.Item label={"Projektstatus"} name="status">
              <Select
                placeholder={"välj status"}
                style={{ width: "100%" }}
                options={[
                  {
                    label: "Pågående",
                    value: "pågående",
                  },
                  {
                    label: "Avslutat",
                    value: "avslutat",
                  },
                  {
                    label: "Pausat",
                    value: "pausat",
                  }
                ]}
              />
            </Form.Item>
          </Form>
        </Create>
      </Drawer>
    );
};
