import { useState, useEffect } from 'react';

interface BoardOption {
  label: string;
  value: string;
}

const orgId : string = "5dd8e85c60d23780d354651a";

const useFetchBoards = () => {
  const [boards, setBoards] = useState<BoardOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBoards = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await fetch(`https://api.trello.com/1/organizations/${orgId}/boards?key=3a24edc2eb6323a3a13457af830ac36c&token=3b7ef6f2cbcc4a3a299f6b11cd7a2fa4a2306f9d11803dcb4e9ad3f4f6e26015`);
        if (!response.ok) {
          throw new Error('Failed to fetch cards for the board');
        }
        const data = await response.json();
        const sortedboards = data.sort((a : any, b : any) => a.name.localeCompare(b.name)); 
        setBoards(sortedboards.map((board : any) => {
            return {
                label: board?.name,
                value: board?.id
            }
        }));
        setLoading(false);
      } catch (error : any) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchBoards();
  }, []);

  return { boards, loading, error };
};

export default useFetchBoards;