import { useTranslate, BaseKey } from "@refinedev/core";

import { Edit, useSelect } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
} from "antd";

const { Text } = Typography;

import { APP_WRITE_PROEJCT_ID } from "utility/constants";
import TextArea from "antd/es/input/TextArea";
import { CustomerSelectOptions } from "components/customerSelectOption";
import { IProject } from "interfaces";

type EditProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    editId?: BaseKey;
};

export const EditProject: React.FC<EditProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    editId,
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();

    const { customerSelectProps } = CustomerSelectOptions();
    // console.log(formProps.initialValues)
    // console.log(customerSelectProps)

    // const test = {value: formProps.initialValues?.customer?.$id, label: formProps.initialValues?.customer?.name};
    // console.log(test)

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Edit
          title={"Redigera projekt"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_PROEJCT_ID}
          recordItemId={editId}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} layout="vertical">
          <Form.Item
              label="Namn"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label="Timpris (kr)"
                name="hourPrice"
                rules={[
                    {
                    required: true,
                    },
                ]}
                >
                <InputNumber min={0}  step="50"/>
            </Form.Item>
            <Form.Item label="Beskrivning" name="description">
              <TextArea rows={10}></TextArea>
            </Form.Item>
            <Form.Item label="Kund" name="customerId">
              <Select
                // defaultValue={{value: formProps.initialValues?.customer?.$id, label: formProps.initialValues?.customer?.name}}
                placeholder="Välj kund"
                style={{ width: "100%" }}
                {...customerSelectProps}
              />
              {/* <select onChange={(e) => console.log(e)} value={"64ba72ffc1e4ee3f3dff"}>
                {customerSelectProps.options?.map((option) => (
                    <option key={option.value} value={option.value ?? ""}>
                        {option.label}
                    </option>
                ))}
            </select> */}
            </Form.Item>
            <Form.Item label={"Projektstatus"} name="status">
              <Select
                placeholder={"välj status"}
                style={{ width: "100%" }}
                options={[
                  {
                    label: "Pågående",
                    value: "pågående",
                  },
                  {
                    label: "Avslutat",
                    value: "avslutat",
                  },
                  {
                    label: "Pausat",
                    value: "pausat",
                  }
                ]}
              />
            </Form.Item>

          </Form>
        </Edit>
      </Drawer>
    );
};
