import React, { useState } from 'react'
import { HttpError, useTranslate, useOne } from "@refinedev/core";
import { Create } from "@refinedev/antd";
import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    ButtonProps,
    Typography,
    Grid,
    InputNumber,
    DatePicker,
    Space,
    Divider,
} from "antd";

const { Text } = Typography;

import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import locale from 'antd/es/date-picker/locale/sv_SE';
import { APP_WRITE_PROEJCT_ID, APP_WRITE_TIME_ROW_ID } from "utility/constants";
import Select from "antd/lib/select";
import { ProjectSelectOptions } from "components/projectSelectOptions";
import { TrelloSearchInput } from "components/common/trelloSearchInput";
import useFetchBoards from 'components/hooks/trelloBoardsHook';
import { IProject, IUser } from 'interfaces';

type CreateProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    currentUser: any;
};

export const CreateTimeRow: React.FC<CreateProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    currentUser
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const [currentDescValue, setCurrentDescValue] = useState<string | undefined>(undefined);
    const [boardId, setBoardId] = useState<string | undefined>(undefined);
    const { projectSelectProps } = ProjectSelectOptions();
    const [projectId, setProjectId] = useState<string | undefined>(undefined);

    const { data : selectedProject, isLoading, isError } = useOne<IProject, HttpError>({
        resource: APP_WRITE_PROEJCT_ID,
        id: projectId
    });

    const { boards } = useFetchBoards();

    const handleOnFinish = (values : any) => {
      formProps.onFinish?.(
        {...values, userId: currentUser?.$id, topic: currentDescValue, price: selectedProject?.data?.hourPrice}
      );
    };

    const onTrelloSelectChanged = (valueObj : any) => {
      setCurrentDescValue(valueObj?.label);
    }

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Create
          title={"Rapportera ny tid"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_TIME_ROW_ID}
          goBack={false}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} onFinish={handleOnFinish} layout="vertical" initialValues={{date: dayjs((new Date()).toISOString(), 'YYYY-MM-DD')}}>
            <Space direction="vertical" style={{marginBottom: '1rem', width: '100%'}}>
              <Text>Hämta från Trello</Text>
              <Select
                  placeholder="Välj trello bräda"
                  optionFilterProp="label"
                  style={{ width: "100%" }}
                  options={boards}
                  showSearch={true}
                  onChange={(value) => setBoardId(value)}
                />
                <TrelloSearchInput onSelectChanged={onTrelloSelectChanged} placeholder="Sök kort i trello" boardId={boardId}></TrelloSearchInput>
            </Space>
            <Form.Item
              label="* Beskrivning"
              name="topic"
              getValueProps={(i) => ({value: currentDescValue})}
              getValueFromEvent={(e) => setCurrentDescValue(e.target.value)}
            >
                <Input/>
            </Form.Item>
            <Form.Item
              label="Projekt"
              name="projectId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Välj projekt"
                optionFilterProp="label"
                style={{ width: "100%" }}
                onChange={(value : any) => setProjectId(value)}
                {...projectSelectProps}
              />
            </Form.Item>
            <Form.Item
              label="Timmar"
              name="amount"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={0.5}  step="0.5"/>
            </Form.Item>
            <Form.Item
              label="Datum"
              name="date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker locale={locale} />
            </Form.Item>
          </Form>
        </Create>
      </Drawer>
    );
};
