import { CrudFilters, HttpError, useMany, useTitle, useUpdate } from "@refinedev/core";
import React, { useState } from 'react'

import { EditButton, List, useDrawerForm, useTable, BooleanField, DeleteButton, CreateButton, NumberField, TextField } from "@refinedev/antd";
import { Button, Checkbox, Drawer, Form, Input, Popconfirm, Space, Table, Tag } from "antd";
import { ICustomer, IProduct, IProspect, ISubscription, ITag } from "interfaces";
import { APP_WRITE_CUSTOMER_ID, APP_WRITE_PROSPECT_ID, APP_WRITE_SUBSCRIPTION_ID } from "utility/constants";
import styled from '@emotion/styled'
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import { CheckCircleOutlined, ScheduleOutlined } from "@ant-design/icons";
import { CreateProspect } from "./create";
import { EditProspect } from "./edit";

const TableHeader= styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
interface ISearch {
    name: string;
}

export const ProspectList: React.FC = () => {
    const { tableProps, searchFormProps } = useTable<IProspect, HttpError, ISearch>(
        {
            onSearch: (values) => {
                const filters: CrudFilters = [];

                filters.push({
                  field: "name",
                  operator: "contains",
                  value: values.name == "" ? undefined : values.name,
                });

                return filters;
            },
            pagination: {
              pageSize: 50
            },
            syncWithLocation: false
        }
    );

    const { mutate } = useUpdate();

    const {
      drawerProps: editDrawerProps,
      formProps: editFormProps,
      saveButtonProps: editSaveButtonProps,
      id: editId,
      show: showEditDrawer,
    } = useDrawerForm<IProspect, HttpError>({
      action: "edit",
      redirect: false,
    });

    const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: showCreateDrawer,
    } = useDrawerForm<IProspect, HttpError>({
      action: "create",
      resource: APP_WRITE_PROSPECT_ID,
      redirect: false,
    });

    const updateContactDate = (prospect : IProspect) => {
      const parsedDate = dayjs().locale("sv").toISOString();
      mutate({
        resource: APP_WRITE_PROSPECT_ID,
        values: {
          latestContact: parsedDate,
        },
        id: prospect?.id,
      });
    };

    const renderContactDate = (latestContact: any) => {
      if (latestContact !== null && dayjs(latestContact).isAfter(dayjs().subtract(30, 'days'))) {
        return (<Tag color="success">{dayjs(latestContact).locale("sv").format("DD MMMM YYYY HH:mm")}</Tag>)
      }

      if (latestContact === null) {
        return (<Tag color="warning">Ingen kontakt</Tag>)
      }

      return (<Tag color="error">{dayjs(latestContact).locale("sv").format("DD MMMM YYYY HH:mm")}</Tag>)
    }

    return (
      <>
        <List title={"Leads"}>
          {/* Table header filters */}
          <Form {...searchFormProps} layout="inline">
            <TableHeader>
              <Space>
                <Form.Item name="name">
                  <Input placeholder="Sök efter leads..." />
                </Form.Item>
                <Button type="primary" onClick={searchFormProps.form?.submit}>
                  Filtrera
                </Button>
              </Space>
              <CreateButton
                resource={APP_WRITE_SUBSCRIPTION_ID}
                onClick={() => showCreateDrawer()}
              />
            </TableHeader>
          </Form>

          <br></br>

          <Table
            {...tableProps}
            rowKey="id"
          >
            <Table.Column dataIndex="name" title="Namn" sorter />
            <Table.Column
              dataIndex="latestContact"
              sorter
              title="Senaste kontakt"
              render={(value) =>
                renderContactDate(value)
              }
            />
            <Table.Column
              dataIndex="revenue"
              title="Omsättning (miljoner kr)"
              sorter
              render={(value) =>
                <TextField value={value} />
              }
            />
            <Table.Column
              dataIndex="currentPlattform"
              title="Plattform"
              sorter
              render={(value) =>
                <Tag>{value}</Tag>
              }
            />
            <Table.Column
              dataIndex="country"
              title="Land"
              sorter
              render={(value) =>
                <Tag>{value}</Tag>
              }
            />
            <Table.Column<IProspect>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => showEditDrawer(record.id)}
                  />
                  <Popconfirm
                    title="Bekräfta nytt kontaktdatum"
                    description={`Ändra "senaste kontaktad" till ${dayjs().locale("sv").format("DD MMMM YYYY HH:mm")}?`}
                    onConfirm={() => updateContactDate(record)}
                    okText="Ja"
                    cancelText="Nej"
                  >
                    <Button size="small"><CheckCircleOutlined></CheckCircleOutlined></Button>
                  </Popconfirm>
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
        <EditProspect
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          editId={editId}
        ></EditProspect>
        <CreateProspect
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
        ></CreateProspect>
      </>
    );
};