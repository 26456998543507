import { CrudFilters, HttpError, useMany, useTitle, useUpdate } from "@refinedev/core";
import React, { useState } from 'react'

import { EditButton, List, useDrawerForm, useTable, BooleanField, DeleteButton, CreateButton, NumberField, TextField } from "@refinedev/antd";
import { Button, Checkbox, Drawer, Form, Input, Popconfirm, Space, Table } from "antd";
import { ICustomer, IProduct, ISubscription, ITag } from "interfaces";
import { APP_WRITE_CUSTOMER_ID, APP_WRITE_SUBSCRIPTION_ID } from "utility/constants";
import styled from '@emotion/styled'
import { CreateSubscription } from "./create";
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import locale from 'antd/es/date-picker/locale/sv_SE';
import { CheckCircleOutlined, ScheduleOutlined } from "@ant-design/icons";
import { EditSubscription } from "./edit";

const TableHeader= styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
interface ISearch {
    name: string;
}

export const SubscriptionList: React.FC = () => {
    const { tableProps, searchFormProps } = useTable<ISubscription, HttpError, ISearch>(
        {
            onSearch: (values) => {
                const filters: CrudFilters = [];

                filters.push({
                  field: "name",
                  operator: "contains",
                  value: values.name == "" ? undefined : values.name,
                });

                return filters;
            },
            pagination: {
              pageSize: 50
            },
            sorters: {
              initial: [
                {
                    field: "expireDate",
                    order: "asc",
                },
              ],
            },
            syncWithLocation: false
        }
    );

    const { mutate } = useUpdate();

    const customerIds = tableProps?.dataSource?.map((item) => item.customerId) ?? [];
    const customers = useMany<ICustomer>({
        resource: APP_WRITE_CUSTOMER_ID,
        ids: customerIds,
        queryOptions: {
            enabled: customerIds.length > 0,
        },
    });

    const {
      drawerProps: editDrawerProps,
      formProps: editFormProps,
      saveButtonProps: editSaveButtonProps,
      id: editId,
      show: showEditDrawer,
    } = useDrawerForm<ISubscription, HttpError>({
      action: "edit",
      redirect: false,
    });

    const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: showCreateDrawer,
    } = useDrawerForm<ISubscription, HttpError>({
      action: "create",
      resource: APP_WRITE_SUBSCRIPTION_ID,
      redirect: false,
    });

    const totalIncome = tableProps?.dataSource?.reduce((accumulator, item) => accumulator + (item.price * 12), 0);

    const extendSubscriptionDate = (subscription : ISubscription) => {
      const parsedDate = dayjs(subscription.expireDate, "YYYY-MM-DD");
      const extendedDate = parsedDate.add(subscription.interval, 'month').startOf('day').toISOString();
      mutate({
        resource: APP_WRITE_SUBSCRIPTION_ID,
        values: {
            expireDate: extendedDate,
        },
        id: subscription?.id,
      });
    };

    return (
      <> 
        <List title={"Driftabonnemang"}>

          {/* Table header filters */}
          <Form {...searchFormProps} layout="inline">
            <TableHeader>
              <Space>
                <Form.Item name="name">
                  <Input placeholder="Sök abonnemang" />
                </Form.Item>
                <Button type="primary" onClick={searchFormProps.form?.submit}>
                  Filtrera
                </Button>
              </Space>
              <CreateButton
                resource={APP_WRITE_SUBSCRIPTION_ID}
                onClick={() => showCreateDrawer()}
              />
            </TableHeader>
          </Form>

          <br></br>

          <Table
            {...tableProps}
            rowKey="id"
            footer={() => `Totalinkomst per år: ${totalIncome} kr exkl. moms`}
          >
            <Table.Column dataIndex="name" title="Namn" sorter/>
            <Table.Column
              dataIndex="customerId"
              title="Kund"
              render={(value) => {
                if (customers?.isLoading) {
                  return <TextField value="Loading..." />;
                }
                let name: string =
                customers.data?.data.find((item) => item.id === value)
                    ?.name ?? "";
                return (
                  <TextField value={name}/>
                );
              }}
            />
            <Table.Column
              dataIndex="expireDate"
              sorter
              title="Utgångsdatum"
              render={(value) =>
                dayjs(value).locale("sv").format("DD MMMM YYYY")
              }
            />
            <Table.Column
              dataIndex="interval"
              title="Förlängning (månader)"
              render={(value) => (
                <NumberField
                  value={value}
                  options={{
                    notation: "standard",
                  }}
                />
              )}
            />
            <Table.Column
              dataIndex="price"
              title="Pris kr/mån"
              render={(value) => <TextField value={value + " kr"} />}
            />
            <Table.Column<ISubscription>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => showEditDrawer(record.id)}
                  />
                   <Popconfirm
                    title="Förläng abonnemang"
                    description={`Vill du förlänga detta abonnemang med ${record.interval} månader?`}
                    onConfirm={() => extendSubscriptionDate(record)}
                    onCancel={(e) => console.log(e)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="small"><CheckCircleOutlined></CheckCircleOutlined></Button>
                  </Popconfirm>
                  <DeleteButton hideText size="small" recordItemId={record.id}/>
                </Space>
              )}
            />
          </Table>
        </List>
        <EditSubscription
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          editId={editId}
        ></EditSubscription>
        <CreateSubscription
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
        ></CreateSubscription>
      </>
    );
};