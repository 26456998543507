import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react';
import {
    Input,
    Space,
    Layout as AntdLayout,
    Button,
    Modal,
    Form,
} from "antd";
import { account } from 'utility/appwriteClient';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useModal } from '@refinedev/antd';
import { useNotification } from "@refinedev/core";

const { Header: AntdHeader } = AntdLayout;

const HeaderWrapper = styled(AntdHeader)`
    height: 64;
    lineHeight: 64px;
    background: white;
    display: flex;
    justify-content: end;
    padding: 0px 24px;
`

export const Header: React.FC = () => {
  const { show, close, modalProps } = useModal();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [form] = Form.useForm();
  const { open : openNotificaton } = useNotification();

  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {
      try {
        // Perform the asynchronous operation (e.g., fetch data from an API)
        const user = await account.get();
        // Update the state with the fetched data
        setCurrentUser(user);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    fetchData();
  }, []);

  const tryChangePassword = async (values : any) => {
    try {
      await account.updatePassword(values.newPassword, values.oldPassword);
      openNotificaton?.({
            type: "success",
            message: "Lösenord ändrat",
            description: "Ditt lösenord har ändrats",
        });
      close();
    }catch(error : any){
      console.log(error)
      setErrorMessage(error.message);
    }
  }

  return (
    <>
      <HeaderWrapper>
        <Space>
          <strong>{currentUser?.name}</strong>{" "}
          <Button icon={<LockOutlined />} onClick={show}></Button>
        </Space>
      </HeaderWrapper>
      <Modal {...modalProps} onOk={form.submit}>
        <h3>Byt lösenord</h3>
        <Form layout="vertical" form={form} onFinish={tryChangePassword}>
          <Form.Item
            label="Nuvarande lösenord"
            name="oldPassword"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="password"/>
          </Form.Item>
          <Form.Item
            label="Nytt lösenord"
            name="newPassword"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="password"/>
          </Form.Item>
        </Form>
        <p style={{color: "red"}}>{errorMessage}</p>
      </Modal>
    </>
  );
};