export const APP_WRITE_CATEGORY_ID = "649e8198bbf13e208492";
export const APP_WRITE_PRODUCT_ID = "649d3e7360d0dbbac767";
export const APP_WRITE_VARIANT_ID = "649e7f17c1eac3c405d3";
export const APP_WRITE_BRAND_ID = "64a128949e59351d065b";
export const APP_WRITE_SUPPLIER_ID = "64a14e3f524da5565f81";
export const APP_WRITE_PRODUCT_TYPE_ID = "64ad350a330c35901fb2";
export const APP_WRITE_TAG_ID = "64ad3514e71d296d1c33";

export const APP_WRITE_CUSTOMER_ID = "64ba3c037c38a166f672";
export const APP_WRITE_PROEJCT_ID = "64ba3d8a5f3bd164266e";
export const APP_WRITE_TIME_ROW_ID = "64bb8fa2d3bf5d9787d4";
export const APP_WRITE_SUBSCRIPTION_ID = "64ba3f37814affb657f4";
export const APP_WRITE_TIME_REPORT_USER_ID = "64cb7174d0059dfe2191";
export const APP_WRITE_PROSPECT_ID = "662f5b6295d6a5af387e";
export const APP_WRITE_DB_ID = "64ba3bdcf2272dd896a4";
export const APP_WRITE_STORAGE_ID = "64ba884c24ae5ceef07b";