import { useTranslate } from "@refinedev/core";
import { Create } from "@refinedev/antd";
import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid
} from "antd";
import TextArea from "antd/es/input/TextArea";
const { Text } = Typography;

import { APP_WRITE_CUSTOMER_ID } from "utility/constants";

type CreateProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreateCustomer: React.FC<CreateProps> = ({
    drawerProps,
    formProps,
    saveButtonProps
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Create
          title={"Skapa kund"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_CUSTOMER_ID}
          goBack={false}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} layout="vertical">
            <Form.Item
              label="Namn"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="OrgNummer"
              name="orgNumber"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Beskrivning"
              name="description"
            >
                <TextArea rows={10}></ TextArea >
            </Form.Item>

          </Form>
        </Create>
      </Drawer>
    );
};
