import { useLink } from "@refinedev/core";
import { theme } from "antd";
import {ReactComponent as HyperloopLogo} from '../../assets/hyperloop.svg';

const { useToken } = theme;

type LogoProps = {
    collapsed: boolean;
};

export const Logo: React.FC<LogoProps> = ({ collapsed }) => {
    const { token } = useToken();
    const Link = useLink();

    return (
    <Link to="/" style={{width: '100%'}}>
        <HyperloopLogo style={{width: '100%'}}>Hyperloop</HyperloopLogo>
    </Link>
    );
};
