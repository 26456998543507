import { useTranslate, useApiUrl, BaseKey } from "@refinedev/core";

import { Edit, useSelect } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
    DatePicker,
} from "antd";

const { Text } = Typography;

import { APP_WRITE_SUBSCRIPTION_ID } from "utility/constants";
import { CustomerSelectOptions } from "components/customerSelectOption";
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import locale from 'antd/es/date-picker/locale/sv_SE';

type EditProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    editId?: BaseKey;
};

export const EditSubscription: React.FC<EditProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    editId,
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const { customerSelectProps } = CustomerSelectOptions();

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Edit
          title={"Redigera driftabonnemang"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_SUBSCRIPTION_ID}
          recordItemId={editId}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} layout="vertical">
          <Form.Item
              label="Namn"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Kund" name="customerId">
              <Select
                placeholder="Välj kund"
                style={{ width: "100%" }}
                {...customerSelectProps}
              />
            </Form.Item>
            <Form.Item
              label="Förlängningsperiod (månader)"
              name="interval"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={1}  step="1" addonAfter="mån"/>
            </Form.Item>
            <Form.Item
              label="Pris"
              name="price"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={1}  step="1" addonAfter="kr/mån"/>
            </Form.Item>
            <Form.Item
              label="Utgångsdatum"
              name="expireDate"
              rules={[
                {
                  required: true,
                },
              ]}
              getValueProps={(i) => ({value: dayjs(i)})}
            >
              <DatePicker locale={locale} />
            </Form.Item>

          </Form>
        </Edit>
      </Drawer>
    );
};
