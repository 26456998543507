import React, { useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({ fetchOptions, debounceTimeout = 500, ...props }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

// Usage of DebounceSelect
interface SelectValue {
  label: string;
  value: string;
}

async function sendTrelloRequest(searchValue: string, boardId: string | undefined): Promise<SelectValue[]> {
  // console.log('fetching user', username);

  if(boardId){
    return fetch(
      `https://api.trello.com/1/search?modelTypes=cards&query=${searchValue}&idBoards=${boardId}&key=3a24edc2eb6323a3a13457af830ac36c&token=3b7ef6f2cbcc4a3a299f6b11cd7a2fa4a2306f9d11803dcb4e9ad3f4f6e26015`
    )
      .then((response) => response.json())
      .then((body) => {
        return body?.cards?.map((card :any) => {
          return(
            {
              label: `${card?.name} #${card?.idShort}`,
              value: card?.id
            }
            
          )
        });
      });
  }

  return fetch(
    `https://api.trello.com/1/search?modelTypes=cards&query=${searchValue}&key=3a24edc2eb6323a3a13457af830ac36c&token=3b7ef6f2cbcc4a3a299f6b11cd7a2fa4a2306f9d11803dcb4e9ad3f4f6e26015`
  )
    .then((response) => response.json())
    .then((body) => {
      return body?.cards?.map((card :any) => {
        return(
          {
            label: `${card?.name} #${card?.idShort}`,
            value: card?.id
          }
          
        )
      });
    });
}

interface TrelloSearchInputProps {
  onSelectChanged: (value: SelectValue | SelectValue[]) => void;
  placeholder?: string;
  boardId?: string | undefined;
}

export const TrelloSearchInput: React.FC<TrelloSearchInputProps> = ({onSelectChanged, placeholder, boardId}) => {
  const [value, setValue] = useState<SelectValue[]>([]);

  return (
    <DebounceSelect
      value={value}
      showSearch={true}
      placeholder={placeholder}
      fetchOptions={(v) => sendTrelloRequest(v, boardId)}
      onChange={(newValue) => {
        setValue(newValue as SelectValue[]);
        onSelectChanged(newValue);
      }}
      style={{ width: '100%' }}
    />
  );
};