import { CrudFilters, HttpError, useMany, useTitle } from "@refinedev/core";
import React, { useEffect, useState } from 'react'

import { EditButton, List, useDrawerForm, useTable, TextField, DeleteButton, CreateButton, NumberField } from "@refinedev/antd";
import { Button, ConfigProvider, DatePicker, Form, Input, Select, Space, Table } from "antd";
import { IProject, ITimeRow } from "interfaces";
import { APP_WRITE_PROEJCT_ID, APP_WRITE_TIME_ROW_ID } from "utility/constants";
import styled from '@emotion/styled'
import { ProjectSelectOptions } from "components/projectSelectOptions";
import { CreateTimeRow } from "./create";
import { Dayjs } from "dayjs";
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import locale from 'antd/locale/sv_SE';
import { account } from "utility/appwriteClient";
import { EditTimeRow } from "./edit";
import { TrelloSearchInput } from "components/common/trelloSearchInput";

const TableHeader= styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
interface ISearch {
    topic: string;
    project: string;
    dateInterval: [Dayjs, Dayjs];
}

const getBeginingOfMonth = () =>{
  let today = new Date();
  today.setDate(0);
  today.setHours(3, 0, 0, 0);
  return today.toISOString();
}

const getEndOfMonth = () =>{
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1, 1);
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate.toISOString();
}


export const TimeRowList: React.FC = () => {
    const [currentUser, setCurrentUser] = useState<any>(null);

    useEffect(() => {
      // Define an async function inside useEffect
      const fetchData = async () => {
        try {
          // Perform the asynchronous operation (e.g., fetch data from an API)
          const user = await account.get();
          // console.log(user)
          // Update the state with the fetched data
          setCurrentUser(user);
        } catch (error) {
          console.error('Error fetching user:', error);
        }
      };
      fetchData();
    }, []);

    const { tableProps, searchFormProps } = useTable<ITimeRow, HttpError, ISearch>(
        {
            sorters: {
              initial: [
                {
                    field: "date",
                    order: "desc",
                },
              ],
            },
            filters: {
              initial: [
                  {
                      field: "date",
                      operator: "gte",
                      value: getBeginingOfMonth(),
                  },
              ],
              permanent: [
                {
                    field: "userId",
                    operator: "eq",
                    value: currentUser?.$id ?? "",
                },
              ],
            },
            pagination:{
              pageSize: 10
            },
            onSearch: (values) => {
                const filters: CrudFilters = [];

                filters.push({
                  field: "topic",
                  operator: "contains",
                  value: values.topic == "" ? undefined : values.topic,
                });

                filters.push({
                  field: "projectId",
                  operator: "eq",
                  value: values.project,
                });

                if(values?.dateInterval?.length > 0){
                  filters.push({
                    field: "date",
                    operator: "gte",
                    value: values.dateInterval[0].format()
                  });
                  filters.push({
                    field: "date",
                    operator: "lte",
                    value: values.dateInterval[1].format()
                  });
                }

                return filters;
            },
            syncWithLocation: false
        }
    );
    
    const projectIds = tableProps?.dataSource?.map((item) => item.projectId) ?? [];
    const projects = useMany<IProject>({
        resource: APP_WRITE_PROEJCT_ID,
        ids: projectIds,
        queryOptions: {
            enabled: projectIds.length > 0,
        },
    });

    const { RangePicker } = DatePicker;
    const { projectSelectProps } = ProjectSelectOptions();

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        id: editId,
        show: showEditDrawer,
    } = useDrawerForm<ITimeRow, HttpError>({
        action: "edit",
        redirect: false,
    });

    const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: showCreateDrawer,
  } = useDrawerForm<ITimeRow, HttpError>({
      action: "create",
      resource: APP_WRITE_TIME_ROW_ID,
      redirect: false,
  });

    return (
      <>
        <List title={"Rapportera din tid"}>
          {/* Table header filters */}
          <Form {...searchFormProps} layout="inline">
            <TableHeader>
              <Space>
                <Form.Item name="topic">
                  <Input placeholder="Sök rapportering" />
                </Form.Item>
                <Form.Item name="project">
                  <Select
                    allowClear={true}
                    placeholder="Välj projekt"
                    style={{ width: "100%" }}
                    {...projectSelectProps}
                  />
                </Form.Item>
                <ConfigProvider locale={locale}>
                  <Form.Item name="dateInterval">
                    <RangePicker
                      defaultValue={[
                        dayjs(getBeginingOfMonth(), "YYYY-MM-DD"),
                        dayjs(getEndOfMonth(), "YYYY-MM-DD"),
                      ]}
                    />
                  </Form.Item>
                </ConfigProvider>
                <Button type="primary" onClick={searchFormProps.form?.submit}>
                  Filtrera
                </Button>
              </Space>
              <CreateButton
                resource={APP_WRITE_TIME_ROW_ID}
                onClick={() => showCreateDrawer()}
              />
            </TableHeader>
          </Form>

          <br></br>

          <Table {...tableProps} rowKey="id">
            {/* <Table.Column dataIndex="id" title="Id" /> */}
            <Table.Column dataIndex="topic" title="Beskrivning" sorter />
            <Table.Column
              dataIndex="date"
              sorter
              title="Datum"
              render={(value) =>
                dayjs(value).locale("sv").format("DD MMMM YYYY")
              }
            />
            <Table.Column
              dataIndex="amount"
              title="Timmar"
              render={(value) => (
                <NumberField
                  value={value}
                  options={{
                    notation: "standard",
                  }}
                />
              )}
            />
            <Table.Column
              dataIndex="projectId"
              title="Projekt"
              render={(value) => {
                if (projects?.isLoading) {
                  return <TextField value="Loading..." />;
                }
                let name: string =
                  projects.data?.data.find((item) => item.id === value)?.name ??
                  "";
                return <TextField value={name} />;
              }}
            />
            <Table.Column<ITimeRow>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => showEditDrawer(record.id)}
                  />
                  <DeleteButton
                    hideText
                    size="small"
                    disabled={record.invoiced}
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
        <EditTimeRow
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          editId={editId}
        ></EditTimeRow>
        <CreateTimeRow
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
          currentUser={currentUser}
        ></CreateTimeRow>
      </>
    );
};