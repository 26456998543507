import { CrudFilters, HttpError, useMany, useExport } from "@refinedev/core";
import React, { useState } from 'react'

import { EditButton, List, SaveButton, TagField, useDrawerForm, FilterDropdown, useTable, Edit, BooleanField, TextField, useSelect, DeleteButton, CreateButton, NumberField, ExportButton } from "@refinedev/antd";
import { Button, Checkbox, Drawer, Form, Input, Select, Space, Table } from "antd";
import { ICustomer, IProject, ITimeRow } from "interfaces";
import { APP_WRITE_CUSTOMER_ID, APP_WRITE_PROEJCT_ID } from "utility/constants";
import styled from '@emotion/styled'
import { CreateProject } from "./create";
import { stringToColor } from "utility/helper";
import { CustomerSelectOptions } from "components/customerSelectOption";
import { EditProject } from "./edit";
import { documentData } from "utility/appwriteClient";

const TableHeader= styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
interface ISearch {
    name: string;
    customer: string;
    status: string;
}

export const ProjectList: React.FC = () => {
    const { tableProps, searchFormProps } = useTable<IProject, HttpError, ISearch>(
        {
            filters: {
              initial: [
                {
                  field: "status",
                  operator: "eq",
                  value: "pågående"
                }
              ]
            },
            onSearch: (values) => {
                const filters: CrudFilters = [];

                filters.push({
                  field: "name",
                  operator: "contains",
                  value: values.name == "" ? undefined : values.name,
                });

                console.log(values)

                filters.push({
                  field: "customerId",
                  operator: "eq",
                  value: values.customer,
                });

                filters.push({
                  field: "status",
                  operator: "eq",
                  value: values.status,
                });

                return filters;
            },
            syncWithLocation: false
        }
    );
    
    const customerIds = tableProps?.dataSource?.map((item) => item.customerId) ?? [];
    const customers = useMany<ICustomer>({
        resource: APP_WRITE_CUSTOMER_ID,
        ids: customerIds,
        queryOptions: {
            enabled: customerIds.length > 0,
        },
    });

    const { customerSelectProps } = CustomerSelectOptions();

    const {
      drawerProps: editDrawerProps,
      formProps: editFormProps,
      saveButtonProps: editSaveButtonProps,
      id: editId,
      show: showEditDrawer,
    } = useDrawerForm<IProject, HttpError>({
      action: "edit",
      redirect: false,
    });

    const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: showCreateDrawer,
    } = useDrawerForm<IProject, HttpError>({
      action: "create",
      resource: APP_WRITE_PROEJCT_ID,
      redirect: false,
    });

    return (
      <> 
        <List title={"Våra projekt"}>

          {/* Table header filters */}
          <Form {...searchFormProps} layout="inline">
            <TableHeader>
              <Space>
                <Form.Item name="name">
                  <Input placeholder="Sök projekt" />
                </Form.Item>
              <Form.Item name="customer">
                <Select
                  allowClear={true}
                  placeholder="Välj kund"
                  style={{ width: "100%" }}
                  {...customerSelectProps}
                />
              </Form.Item>
              <Form.Item name="status">
                <Select
                  placeholder={"filtrera status"}
                  defaultValue={"pågående"}
                  allowClear={true}
                  style={{ width: "100%" }}
                  options={[
                    {
                      label: "Pågående",
                      value: "pågående",
                    },
                    {
                      label: "Avslutat",
                      value: "avslutat",
                    },
                    {
                      label: "Pausat",
                      value: "pausat",
                    }
                  ]}
                />
              </Form.Item>
              <Button type="primary" onClick={searchFormProps.form?.submit}>
                Filtrera
              </Button>
              </Space>
              <CreateButton
                resource={APP_WRITE_PROEJCT_ID}
                onClick={() => showCreateDrawer()}
              />
            </TableHeader>
          </Form>

          <br></br>

          <Table
            {...tableProps}
            rowKey="id"
          >
            {/* <Table.Column dataIndex="id" title="Id" /> */}
            <Table.Column dataIndex="name" title="Namn" sorter/>
            <Table.Column
              dataIndex="status"
              title="Status"
              render={(value) => {
                return (
                  <TagField
                    value={value}
                    color={stringToColor(value)}
                  ></TagField>
                );
              }}
            />
            <Table.Column
              dataIndex="customerId"
              title="Kund"
              render={(value) => {
                if (customers?.isLoading) {
                  return <TextField value="Loading..." />;
                }
                let name: string =
                customers.data?.data.find((item) => item.id === value)
                    ?.name ?? "";
                return (
                  <TextField value={name}/>
                );
              }}
            />
            <Table.Column
              dataIndex="hourPrice"
              title="TimPris"
              render={(value) => <TextField value={value + " kr"} />}
            />
            <Table.Column<IProject>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => showEditDrawer(record.id)}
                  />
                  <DeleteButton hideText size="small" recordItemId={record.id} disabled={true}/>
                </Space>
              )}
            />
          </Table>
        </List>
        <EditProject
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          editId={editId}
        ></EditProject>
        <CreateProject
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
        ></CreateProject>
      </>
    );
};