import { useTranslate, BaseKey } from "@refinedev/core";

import { Edit, getValueFromEvent } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
    Image,
} from "antd";

const { Text } = Typography;

import { APP_WRITE_CUSTOMER_ID, APP_WRITE_DB_ID, APP_WRITE_STORAGE_ID } from "utility/constants";
import TextArea from "antd/es/input/TextArea";
import { documentData, storage } from "utility/appwriteClient";
import { RcFile } from "antd/es/upload";

type EditProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    editId?: BaseKey;
};

export const EditCustomer: React.FC<EditProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    editId,
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Edit
          title={"Redigera Kund"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_CUSTOMER_ID}
          recordItemId={editId}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} layout="vertical">
            <Form.Item
              label="Namn"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="OrgNummer" name="orgNumber">
              <Input />
            </Form.Item>
            <Form.Item label="Beskrivning" name="description">
              <TextArea rows={10}></TextArea>
            </Form.Item>
            <Form.Item
              name="logo"
              label="Logotyp"
              getValueFromEvent={getValueFromEvent}
            >
              {/* <Space direction="vertical" style={{width: '100%'}}> */}
              <Image
                src={formProps?.initialValues?.logo}
                style={{marginBottom: '1.5rem'}}
              />
              <Upload.Dragger
                name="file"
                listType="picture"
                maxCount={1}
                customRequest={async ({ file, onError, onSuccess }) => {
                  try {
                    const rcFile = file as RcFile;

                    const { $id } = await storage.createFile(
                      APP_WRITE_STORAGE_ID,
                      rcFile.name,
                      rcFile
                    );

                    const url = storage.getFileView(APP_WRITE_STORAGE_ID, $id);

                    await documentData.updateDocument(APP_WRITE_DB_ID, APP_WRITE_CUSTOMER_ID, formProps?.initialValues?.id, {logo: url.toString()});

                    onSuccess?.({ url }, new XMLHttpRequest());
                  } catch (error) {
                    onError?.(new Error("Upload Error"));
                  }
                }}
              >
                <p className="ant-upload-text">Ladda upp logotyp här!</p>
              </Upload.Dragger>
              {/* </Space> */}
            </Form.Item>
          </Form>
        </Edit>
      </Drawer>
    );
};
