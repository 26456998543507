import { useTranslate, BaseKey } from "@refinedev/core";

import { Edit, useSelect } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Select,
    ButtonProps,
    Typography,
    Grid,
    DatePicker,
} from "antd";

const { Text } = Typography;
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import locale from 'antd/es/date-picker/locale/sv_SE';
import { APP_WRITE_TIME_ROW_ID } from "utility/constants";
import { ProjectSelectOptions } from "components/projectSelectOptions";

type EditProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    editId?: BaseKey;
};

export const EditTimeRow: React.FC<EditProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    editId
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const { projectSelectProps } = ProjectSelectOptions();
 
    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Edit
          title={"Redigera tid"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_TIME_ROW_ID}
          recordItemId={editId}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} layout="vertical">
            <Form.Item
                label="Beskrivning"
                name="topic"
                rules={[
                    {
                    required: true,
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label="Projekt"
                name="projectId"
                rules={[
                    {
                    required: true,
                    },
                ]}
                >
                <Select
                    placeholder="Välj projekt"
                    style={{ width: "100%" }}
                    {...projectSelectProps}
                />
                </Form.Item>
                <Form.Item
                label="Timmar"
                name="amount"
                rules={[
                    {
                    required: true,
                    },
                ]}
                >
                <InputNumber min={0.5}  step="0.5"/>
                </Form.Item>
                <Form.Item
                label="Datum"
                name="date"
                rules={[
                    {
                    required: true,
                    },
                ]}
                getValueProps={(i) => ({value: dayjs(i)})}
                >
                <DatePicker locale={locale}/>
                </Form.Item>
          </Form>
        </Edit>
      </Drawer>
    );
};
