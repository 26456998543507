import { CrudFilters, HttpError, useList, useMany, useExport, useUpdateMany } from "@refinedev/core";
import React, { useEffect, useState } from 'react'

import { EditButton, List, useDrawerForm, useTable, TextField, DeleteButton, CreateButton, NumberField, BooleanField, ExportButton } from "@refinedev/antd";
import { Button, Col, ConfigProvider, DatePicker, DatePickerProps, Form, Input, Row, Select, Space, Table } from "antd";
import { IProject, ITimeRow, IUser } from "interfaces";
import { APP_WRITE_PROEJCT_ID, APP_WRITE_TIME_REPORT_USER_ID, APP_WRITE_TIME_ROW_ID } from "utility/constants";
import styled from '@emotion/styled'
import { ProjectSelectOptions } from "components/projectSelectOptions";
// import { CreateTimeRow } from "./create";
import { Dayjs } from "dayjs";
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import locale from 'antd/locale/sv_SE';
import { account } from "utility/appwriteClient";
import { EditTimeRow } from "../timeRows/edit";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { UserSelectOptions } from "components/userSelectOptions";

const TableHeader= styled.div`
  width: 100%;

  .ant-form-item {
    margin-inline-end: 0px;
  }
`

interface ISearch {
    topic: string;
    project: string;
    dateInterval: [Dayjs, Dayjs];
    user: string;
}

const getBeginingOfMonth = () =>{
  let today = new Date();
  today.setDate(-35);
  today.setHours(3, 0, 0, 0);
  return today.toISOString();
}

const getEndOfMonth = () =>{
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1, 1);
  currentDate.setDate(currentDate.getDate() + 0);
  return currentDate.toISOString();
}

function groupByAndCount<T>(arr: T[] | undefined, property: keyof T): Record<string, number> {
  const result: Record<string, number> = {};

  arr?.forEach(item => {
    const group = String(item[property]);
    if (!result[group]) {
      result[group] = 1;
    } else {
      result[group]++;
    }
  });

  return result;
}

export const InvoicingList: React.FC = () => {
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [globalFilter, setGlobalFilter] = useState<any>([
      { field: "date", operator: "gte", value: getBeginingOfMonth() },
      { field: "date", operator: "lte", value: getEndOfMonth() },
      { field: "invoiced", operator: "eq", value: false },
    ]);
    const [filter, setFilter] = useState<React.Key[]>(["1"]);
    const { mutate, isLoading : multiLoading } = useUpdateMany<ITimeRow>();

    //Custom code for getting stats on project, may be replaced!
    const { data, isLoading, isError } = useList<ITimeRow, HttpError>({
      resource: APP_WRITE_TIME_ROW_ID,
      filters: [
        {
            field: "invoiced",
            operator: "eq",
            value: false,
        },
      ],
      pagination:{
        pageSize: 100
      },
    });

    const projectStats = groupByAndCount(data?.data, "projectId")

    useEffect(() => {
      // Define an async function inside useEffect
      const fetchData = async () => {
        try {
          // Perform the asynchronous operation (e.g., fetch data from an API)
          const user = await account.get();
          // console.log(user)
          // Update the state with the fetched data
          setCurrentUser(user);
        } catch (error) {
          console.error('Error fetching user:', error);
        }
      };
      fetchData();
    }, []);

    const updateTimeRows = (status : boolean) => {
      mutate({
        resource: APP_WRITE_TIME_ROW_ID,
        values: {
          invoiced : status
        },
        ids: selectedRowKeys.map(String),
      },
      {
        onError: (error, variables, context) => {
            console.log("Multi published failed", error)
        },
        onSuccess: (data, variables, context) => {
          setSelectedRowKeys([])
        },
      })
    }

    const { tableProps, searchFormProps } = useTable<ITimeRow, HttpError, ISearch>(
        {
            resource: APP_WRITE_TIME_ROW_ID,
            sorters: {
              initial: [
                {
                    field: "date",
                    order: "asc",
                },
              ],
            },
            filters: {
              initial: [
                  {
                      field: "date",
                      operator: "gte",
                      value: getBeginingOfMonth(),
                  },
                  {
                    field: "invoiced",
                    operator: "eq",
                    value: false,
                  }
              ]
            },
            pagination:{
              pageSize: 50
            },
            onSearch: (values) => {
                const filters: CrudFilters = [];

                filters.push({
                  field: "topic",
                  operator: "contains",
                  value: values.topic == "" ? undefined : values.topic,
                });

                filters.push({
                  field: "projectId",
                  operator: "eq",
                  value: values.project,
                });

                if(values?.dateInterval?.length > 0){
                  filters.push({
                    field: "date",
                    operator: "gte",
                    value: values.dateInterval[0].format()
                  });
                  filters.push({
                    field: "date",
                    operator: "lte",
                    value: values.dateInterval[1].format()
                  });
                }

                if(filter.includes('1')){
                  filters.push({
                    field: "invoiced",
                    operator: "eq",
                    value: false,
                  });
                }

                if(filter.includes('2')){
                  filters.push({
                    field: "invoiced",
                    operator: "eq",
                    value: true,
                  });
                }

                if(!filter.includes('1') && !filter.includes('2')){
                  filters.push({
                    field: "invoiced",
                    operator: "eq",
                    value: undefined,
                  });
                }

                filters.push({
                  field: "userId",
                  operator: "eq",
                  value: values.user,
                });

                setGlobalFilter(filters.filter(f => f.value !== undefined));

                return filters;
            },
            syncWithLocation: false
        }
    );
    
    const projectIds = tableProps?.dataSource?.map((item) => item.projectId) ?? [];
    const projects = useMany<IProject>({
        resource: APP_WRITE_PROEJCT_ID,
        ids: projectIds,
        queryOptions: {
            enabled: projectIds.length > 0,
        },
    });

    const userIds = tableProps?.dataSource?.map((item) => item.userId) ?? [];
    const users = useMany<IUser>({
        resource: APP_WRITE_TIME_REPORT_USER_ID,
        ids: userIds,
        queryOptions: {
            enabled: userIds.length > 0,
        },
    });

    const { RangePicker } = DatePicker;
    const { projectSelectProps } = ProjectSelectOptions();
    const { userSelectProps } = UserSelectOptions();
    const formattedProjectOptions = projectSelectProps?.options?.map((p) => { return {label: `${p?.label} (${projectStats[p?.value || 0] ?? 0})`, value: p?.value}})
    const formattedProjectSelectProps = { ...projectSelectProps, options: formattedProjectOptions };

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        id: editId,
        show: showEditDrawer,
    } = useDrawerForm<ITimeRow, HttpError>({
        action: "edit",
        redirect: false,
        resource: APP_WRITE_TIME_ROW_ID
    });


    const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: showCreateDrawer,
  } = useDrawerForm<ITimeRow, HttpError>({
      action: "create",
      resource: APP_WRITE_TIME_ROW_ID,
      redirect: false,
  });

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const { triggerExport, isLoading: exportLoading } = useExport<ITimeRow>({
      resource: APP_WRITE_TIME_ROW_ID,
      sorters: 
        [
          {
              field: "date",
              order: "asc",
          },
        ],
      filters: globalFilter,
      exportOptions: {
        quoteStrings: "",
        useTextFile: true,
        fieldSeparator: " "
      },
      mapData: (item) => {
        return {
            Datum: dayjs(item.date).format('YYYY-MM-DD'),
            Timmar: `${item.amount}h`,
            Beskrivning: item.topic
        };
      },
    });

    const dateFormat = 'YYYY-MM-DD';
    const customFormat: DatePickerProps['format'] = (value) =>
    `${value.format(dateFormat)}`;
    
    const totalHours = tableProps?.dataSource?.reduce((accumulator, item) => accumulator + item.amount, 0);
    const totalAmount = tableProps?.dataSource?.reduce((accumulator, item) => accumulator + (item.amount * item.price), 0);

    // const userData = tableProps?.dataSource?.map((obj) => {
    //   return JSON.parse(obj?.userData);
    // });

    return (
      <>
        <List title={"Fakturering"}>
          {/* Table header filters */}
          <Form {...searchFormProps} layout="inline">
            <TableHeader>
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item name="topic">
                    <Input placeholder="Sök rapportering" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item name="project">
                    <Select
                      allowClear={true}
                      placeholder="Välj projekt"
                      style={{ width: "100%" }}
                      {...formattedProjectSelectProps}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Select
                    mode="multiple"
                    allowClear
                    value={filter}
                    style={{ width: "100%" }}
                    placeholder="Välj filter"
                    onChange={(value) => setFilter(value)}
                    options={[
                      { label: "Ej hanterade", value: "1" },
                      { label: "Hanterade", value: "2" },
                    ]}
                  />
                </Col>
                <Col xs={24} lg={24} xl={12}>
                  <ConfigProvider locale={locale}>
                    <Form.Item name="dateInterval">
                      <RangePicker
                        style={{ width: "100%" }}
                        format={customFormat}
                        showTime={{
                          showHour: false,
                          showMinute: false,
                          showSecond: false,
                          hideDisabledOptions: true,
                          defaultValue: [
                            dayjs("00:00:00", "HH:mm:ss"),
                            dayjs("00:00:00", "HH:mm:ss"),
                          ],
                        }}
                        defaultValue={[
                          dayjs(getBeginingOfMonth(), dateFormat),
                          dayjs(getEndOfMonth(), dateFormat),
                        ]}
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Col>
                <Col xs={24} lg={24} xl={12}>
                  <Form.Item name="user">
                    <Select
                      allowClear={true}
                      placeholder="Välj användare"
                      style={{ width: "100%" }}
                      {...userSelectProps}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Space
                style={{
                  marginTop: "0.5rem",
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <Space
                  style={{
                    marginTop: "0.5rem",
                  }}
                >
                  <Button type="primary" onClick={searchFormProps.form?.submit}>
                    Filtrera
                  </Button>
                  {selectedRowKeys.length > 0 && (
                    <>
                      <Button
                        type="text"
                        onClick={() => updateTimeRows(true)}
                        loading={isLoading}
                        icon={
                          <CheckCircleOutlined style={{ color: "green" }} />
                        }
                      >
                        {"Markera som hanterade"}
                      </Button>
                      <Button
                        type="text"
                        onClick={() => updateTimeRows(false)}
                        loading={isLoading}
                        icon={<CloseCircleOutlined style={{ color: "red" }} />}
                      >
                        {"Markera som ej hanterade"}
                      </Button>
                    </>
                  )}
                </Space>
                <ExportButton onClick={triggerExport} loading={exportLoading} />
              </Space>
            </TableHeader>
          </Form>

          <br></br>

          <Table
            {...tableProps}
            rowKey="id"
            rowSelection={rowSelection}
            footer={() =>
              `Timmar: ${totalHours} Inkomst: ${totalAmount} kr exkl. moms`
            }
          >
            {/* <Table.Column dataIndex="id" title="Id" /> */}
            <Table.Column dataIndex="topic" title="Beskrivning" sorter />
            <Table.Column
              dataIndex="userId"
              title="Användare"
              sorter
              render={(value) => {
                if (users?.isLoading) {
                  return <TextField value="Loading..." />;
                }
                let name: string =
                users.data?.data.find((item) => item.id === value)?.name ??
                  "";
                return <TextField value={name} />;
              }}
            />
            <Table.Column
              dataIndex="date"
              sorter
              title="Datum"
              render={(value) =>
                dayjs(value).locale("sv").format("DD MMMM YYYY")
              }
            />
            <Table.Column
              dataIndex="amount"
              title="Timmar"
              render={(value) => (
                <NumberField
                  value={value}
                  options={{
                    notation: "standard",
                  }}
                />
              )}
            />
            <Table.Column
              dataIndex="projectId"
              title="Projekt"
              render={(value) => {
                if (projects?.isLoading) {
                  return <TextField value="Loading..." />;
                }
                let name: string =
                  projects.data?.data.find((item) => item.id === value)?.name ??
                  "";
                return <TextField value={name} />;
              }}
            />
            <Table.Column
              align="center"
              dataIndex="invoiced"
              title="Hanterad"
              render={(value) => <BooleanField value={value} />}
            />
            <Table.Column<ITimeRow>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => showEditDrawer(record.id)}
                  />
                  <DeleteButton
                    hideText
                    size="small"
                    disabled={record.invoiced}
                    resource={APP_WRITE_TIME_ROW_ID}
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
        <EditTimeRow
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          editId={editId}
        ></EditTimeRow>
        {/* <CreateTimeRow
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
          currentUserId={currentUser?.$id}
        ></CreateTimeRow> */}
      </>
    );
};
